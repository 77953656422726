<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

        <a-row :gutter="24" class="px-20" style="margin-top: 0px;">
            <a-col :span="24" :md="24" :lg="24" :xl="24" class="">
                <a-badge 
                    v-for="option in filterItems"
                    :key="option.uuid"
                    style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
                    :count="`${option.name}    ${option.icon}`" 
                    @click="onSelectFilterItem(option.uuid)"
                    :number-style="pagination.filter == option.uuid ? 
                        {backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '5px', paddingRight: '18px', paddingLeft: '18px', height: '32px', fontSize: '14px'} : 
                        {border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: 'transparent !important', paddingTop: '5px', paddingRight: '18px', paddingLeft: '18px', height: '32px', fontSize: '14px'}"/>
            </a-col>
        </a-row>

        <!-- <a-row :gutter="24" class="px-20">
            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24 text-right">
                <a-pagination type="success" class="btn btn-silabu" v-model="currentPage" :total="pagination.total" :pageSize="pagination.perPage" show-less-items />
            </a-col>
        </a-row> -->

        <div v-if="!loadingData" class="mb-20 mt-20 pt-20">

            <a-row :gutter="24" class="px-20" v-if="pagination.filter == 'incoming-bookings'">

				<a-col :span="24" :xs="24" :sm="24" :md="12" :lg="8" :xl="6" class="mb-24 pb-10" v-for="(book, index) in incomingBookings" :key="book.uuid">
					<a-card class="pb-15" style="">
                        <a-row :gutter="24">
                            <a-col :span="6" :sm="6" :md="6" :lg="6" :xl="6" class="text-center mt-5">
                                <a-avatar :size="70" class="mt-5" :src="book.creator != null ? book.creator.profile : ''" />
                            </a-col>

                            <a-col :span="18" :sm="18" :md="18" :lg="18" :xl="18">
                                <a-row :gutter="24" class="pt-10">
                                        
                                    <a-col :span="24">
                                        <h6 class="mb-0 pb-0 pt-5" v-if="book.creator != null">{{ `${formatName(book.creator.firstName)} ${formatName(book.creator.lastName)}`}}</h6>

                                        <small class="mb-0 pb-0 text-description" style="position: absolute; top: -10px; right: 10px; color: rgba(0, 0, 0, 0.4);">
                                            {{ $Moment(book.createdAt).format('DD MMM HH:mm') }} Hrs
                                        </small>
                                    </a-col>
                                </a-row> 

                                <a-row :gutter="24" class="mt-5">
                                    <a-col :span="24" :md="24" :lg="24" :xl="24">
                                        <a-badge  
                                            style="font-size: 14px !important;" 
                                            :count="`📖 ${book.subject != null ? book.subject.name : 'Unknown'}`" 
                                            :number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px'}"/> &nbsp;
                                    </a-col>
                                </a-row>

                                <a-row :gutter="24" class="mt-5">
                                    <a-col :span="24" :md="24" :lg="24" :xl="24">
                                        <small class="description">Place: <strong> {{ book.location }}</strong></small>
                                    </a-col>
                                </a-row>
                                
                                <a-row :gutter="24" class="mt-10">
                                    <a-col :span="24" :md="24" :lg="24" :xl="24" v-if="book.status == 'ACCEPTED'">
                                        <a-button type="danger" size="small" :loading="book.loading" class="px-10 py-0" @click="declineBooking(book.uuid, index)" style="border: none; height: 30px !important;">
                                            Decline
                                        </a-button>
                                        <a-button id="btnSuccess" :loading="book.loading" size="small" class="px-10 py-0 ml-15" type="success" @click="approveBooking(book.uuid, index)" style="border: none; height: 30px !important;">
                                            Accept Request
                                        </a-button>
                                    </a-col>
                                </a-row>

                                
                            </a-col>

                        </a-row>
                    </a-card>
				</a-col>

			</a-row>


            <a-row :gutter="24" class="px-20" v-if="pagination.filter == 'ongoing-bookings'">

				<a-col :span="24" :sm="12" :md="8" :lg="8" :xl="8" class="mb-24 pb-10" v-for="book in ongoingBookings" :key="book.uuid">
					<a-card class="pb-15" style="">
                        <a-row :gutter="24">
                            <a-col :span="24" :sm="12" :md="6" :lg="6" :xl="6" class="text-center mt-5">
                                <a-avatar :size="70" class="mt-5" :src="book.creator != null ? book.creator.profile : ''" />
                            </a-col>

                            <a-col :span="24" :sm="24" :md="18" :lg="18" :xl="18">
                                <a-row :gutter="24" class="pt-10">
                                        
                                    <a-col :span="24">
                                        <h6 class="mb-0 pb-0 pt-5" v-if="book.creator != null">{{ `${formatName(book.creator.firstName)} ${formatName(book.creator.lastName)}`}}</h6>

                                        <small class="mb-0 pb-0 text-description" style="position: absolute; top: -10px; right: 10px; color: rgba(0, 0, 0, 0.4);">
                                            {{ $Moment(book.createdAt).format('DD MMM HH:mm') }} Hrs
                                        </small>
                                    </a-col>
                                </a-row> 

                                <a-row :gutter="24" class="mt-5">
                                    <a-col :span="24" :md="24" :lg="24" :xl="24">
                                        <a-badge  
                                            style="font-size: 14px !important;" 
                                            :count="`📖 ${book.subject != null ? book.subject.name : 'Unknown'}`" 
                                            :number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px'}"/> &nbsp;
                                    </a-col>
                                </a-row>

                                <a-row :gutter="24" class="mt-5">
                                    <a-col :span="24" :md="24" :lg="24" :xl="24">
                                        <small class="description">Place: <strong> {{ book.location }}</strong></small>
                                    </a-col>
                                </a-row>
                                
                                <a-row :gutter="24" class="mt-10">

                                    <a-col :span="24" :md="24" :lg="24" :xl="24" v-if="book.status == 'ONGOING'">
                                        <a-badge  
                                            style="" 
                                            :count="`${formatName(book.status)}`" 
                                            :number-style="{border: '1px solid #734EBF !important', color: '#FFFFFF', backgroundColor: '#734EBF !important', paddingTop: '2px', height: '26px'}"/>
                                    </a-col>
                                </a-row>

                                
                            </a-col>

                        </a-row>
                    </a-card>
				</a-col>

			</a-row>


            <a-row :gutter="24" class="px-20" v-if="pagination.filter == 'completed-bookings'">

				<a-col :span="24" :sm="12" :md="8" :lg="8" :xl="8" class="mb-24 pb-10" v-for="book in completedBookings" :key="book.uuid">
					<a-card class="pb-15" style="">
                        <a-row :gutter="24">
                            <a-col :span="24" :sm="12" :md="6" :lg="6" :xl="6" class="text-center mt-5">
                                <a-avatar :size="70" class="mt-5" :src="book.creator != null ? book.creator.profile : ''" />
                            </a-col>

                            <a-col :span="24" :sm="24" :md="18" :lg="18" :xl="18">
                                <a-row :gutter="24" class="pt-10">
                                        
                                    <a-col :span="24">
                                        <h6 class="mb-0 pb-0 pt-5" v-if="book.creator != null">{{ `${formatName(book.creator.firstName)} ${formatName(book.creator.lastName)}`}}</h6>

                                        <small class="mb-0 pb-0 text-description" style="position: absolute; top: -10px; right: 10px; color: rgba(0, 0, 0, 0.4);">
                                            {{ $Moment(book.createdAt).format('DD MMM HH:mm') }} Hrs
                                        </small>
                                    </a-col>
                                </a-row> 

                                <a-row :gutter="24" class="mt-5">
                                    <a-col :span="24" :md="24" :lg="24" :xl="24">
                                        <a-badge  
                                            style="font-size: 14px !important;" 
                                            :count="`📖 ${book.subject != null ? book.subject.name : 'Unknown'}`" 
                                            :number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px'}"/> &nbsp;
                                    </a-col>
                                </a-row>

                                <a-row :gutter="24" class="mt-5">
                                    <a-col :span="24" :md="24" :lg="24" :xl="24">
                                        <small class="description">Place: <strong> {{ book.location }}</strong></small>
                                    </a-col>
                                </a-row>
                                
                                <a-row :gutter="24" class="mt-10">
                                    <a-col :span="24" :md="24" :lg="24" :xl="24">
                                        <a-badge  
                                            style="" 
                                            :count="`${book.status}`" 
                                            :number-style="{border: '1px solid #734EBF !important', color: '#FFFFFF', backgroundColor: '#734EBF !important', paddingTop: '4px', height: '30px'}"/>
                                    </a-col>
                                </a-row>

                                
                            </a-col>

                        </a-row>
                    </a-card>
				</a-col>

			</a-row>


        </div>

        <div v-if="!loadingData && ((incomingBookings.length == 0 && pagination.filter == 'incoming-bookings') || (ongoingBookings.length == 0 && pagination.filter == 'ongoing-bookings') || (completedBookings && pagination.filter == 'completed-bookings'))">

            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 50vh">

                <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                    <img src="../../../public/images/no_data.png" alt="" width="100%" style="" class=""> 

                    <h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">No bookings found</h6>
                    
                </a-col>

            </a-row>
        </div>

        <div v-if="loadingData">

            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 60vh">

                <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                    <a-spin class="text-primary" size="large"/>

                </a-col>

            </a-row>
        </div>


        <!-- MODAL -->
        <a-modal v-model="filter.showModal" :footer="null">
            
            <a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
                <a-col :span="24" class="my-0 py-0">
                    <h6 style="font-weight: normal; font-size: 18px;" class="text-center">Select all options that apply to you</h6>
                </a-col>
            </a-row>

            <!-- <a-row :gutter="[24, 24]" class="mt-10 mb-0 pb-0">
                <a-col :span="24" class="">
                    <label class=""><strong>Type</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-radio-group v-model="filter.type">
                        <a-radio :value="'classes'">Classes</a-radio>
                        <a-radio :value="'notes'">Notes</a-radio>
                        <a-radio :value="'quizzes'">Quizzes</a-radio>
                    </a-radio-group>
                </a-col>
            </a-row> -->

            <a-row :gutter="[24, 24]" class="mt-10 mb-0 pb-0">
                <a-col :span="24" class="">
                    <label class="mb-0 pb-0"><strong>Price</strong></label>
                </a-col>
                <a-col :span="24" class="mt-5 pt-0">
                    <a-slider class="mt-0 pt-0 mb-0 pb-0" v-model="filter.price" :tooltip-open="true" :step="slider.step" :max="slider.max" :trackStyle="{background: '#734EBF', color: '#734EBF'}"/>
                    <p style="font-size: 12px; position: absolute; right: 20px; top: -24px;">Less than: TZS <strong>{{ filter.price.toLocaleString() }}</strong></p>
                </a-col>
            </a-row>


            <a-row :gutter="[24, 24]" class="mt-0 mb-0 pb-0">
                <a-col :span="24" class="mb-5 pb-0">
                    <label class="mb-0 pb-0"><strong>Tutor’s Rating</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-rate class="mt-0 pt-0" v-model="filter.tutorRating" />
                </a-col>
            </a-row>


            <a-row :gutter="[24, 24]" class="mt-0 mb-0 pb-0">
                <a-col :span="24" class="">
                    <label class=""><strong>Start Time</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-radio-group v-model="filter.startTime">
                        <a-radio :value="'today'">Starting Today</a-radio>
                        <a-radio :value="'week'">Starting Next week</a-radio>
                        <a-radio :value="'weeks'">Starting in 2 weeks</a-radio>
                    </a-radio-group>
                </a-col>
            </a-row>

            <a-row :gutters="24" class="mt-20 pt-20">
                <a-col :span="24" class="text-right">
                    <a-button id="textBtn" type="text" class="text-primary txtBtn" @click="clearFilter">
                        Clear all filters
                    </a-button>
                    &nbsp;&nbsp;
                    <a-button type="primary" class="text-white txtBtn" style="" @click="submitFilter">
                        Appy filters
                    </a-button>
                </a-col>
            </a-row>
            
        </a-modal>

	</div>
</template>

<script>
    import { notification } from 'ant-design-vue';
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter' ;

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,
                bookingItemLoading: {
                    index: null,
                    loading: null,
                },

				
                incomingBookings: [],
                ongoingBookings: [],
                completedBookings: [],

				currentPage: 1,
                pagination: {
					search: '',
                    filter: 'incoming-bookings',
                    perPage: 12,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

				slider: {
                    max: 40000,
                    min: 0,
                    step: 500
                },


                filterItems: [
                    { uuid: 'incoming-bookings', name: 'Incoming Requests ', icon: ' 📥'},
                    { uuid: 'ongoing-bookings', name: 'Ongoing', icon: ' 🎓' },
                    { uuid: 'completed-bookings', name: 'Completed ', icon: ' 🗃️'}
                ],

                filter: {
                    loading: false,
                    showModal: false,
                    isActive: false,
                    type: null,
                    price: 20000,
                    tutorRating: 3,
                    startTime: null,
                },
      		}
		},
		mounted () {
			let breadcrumbs = [
				{ title: 'Tutoring Jobs', url: '/tutoring-jobs', isActive: true },
			];
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
			this.getActiveItem();
		},
		methods: {

			formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

			submitSearch() {
				this.pagination.currentPage = 1;
				this.getIncomingBookings();
			},

			cancelSearch() {
				this.pagination.search = '';
				this.getIncomingBookings();
			},

			launchFilter() {
                this.filter.showModal = true;
            },

            clearFilter() {
                this.filter.isActive = false;
                this.filter.showModal = false;
                this.getIncomingBookings();
            },

            submitFilter() {
                this.filter.isActive = true;
                this.filter.showModal = false;
                this.getIncomingBookings();
            },

			toCreateNewClass() {
				this.$router.push('/my-classes/create-new-class')
			},


            getActiveItem() {

                if(this.pagination.filter == 'incoming-bookings') {
                    this.getIncomingBookings();
                }

                else if(this.pagination.filter == 'ongoing-bookings') {
                    this.getOngoingBookings();
                }

                else if(this.pagination.filter == 'completed-bookings') {
                    this.getCompletedBookings();
                }

                else{
                    this.getIncomingBookings();
                }
                
            },


            onSelectFilterItem(item) {
                this.pagination.filter = item;

                if(item == 'incoming-bookings') {
                    this.getIncomingBookings();
                }

                else if(item == 'ongoing-bookings') {
                    this.getOngoingBookings();
                }

                else if(item == 'completed-bookings') {
                    this.getCompletedBookings();
                }

                else{
                    this.getIncomingBookings();
                }
                
            },


            async getIncomingBookings() {

                const userInfo = await localStorage.getItem('user_details')

                this.loadingData = true;

				let userDetails = userInfo != null ? JSON.parse(userInfo) : {};

				this.loadingData = true;

                const { currentPage, perPage, search } = this.pagination;

                let url = '';

                if(search != null && search.length > 0) {                       
                    url = `${this.$BACKEND_URL}/bookings?tutor=${userDetails.uuid}&status=ACCEPTED&populate=true&search=${search}&page=${currentPage}&size=${perPage}`;
                }else{
                    url = `${this.$BACKEND_URL}/bookings?tutor=${userDetails.uuid}&status=ACCEPTED&populate=true&page=${currentPage}&size=${perPage}`;
                }

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.incomingBookings = response.data.data;
						this.pagination.totalPages = response.data.pagination != null ? response.data.pagination.totalPages : 1;
                        this.pagination.total = response.data.pagination != null ? response.data.pagination.totalItems : 0;
                        this.pagination.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
						this.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            async getOngoingBookings() {

                const userInfo = await localStorage.getItem('user_details')

                this.loadingData = true;

				let userDetails = userInfo != null ? JSON.parse(userInfo) : {};

				this.loadingData = true;

                const { currentPage, perPage, search } = this.pagination;

                let url = '';

                if(search != null && search.length > 0) {                       
                    url = `${this.$BACKEND_URL}/bookings?tutor=${userDetails.uuid}&status=ONGOING&populate=true&search=${search}&page=${currentPage}&size=${perPage}`;
                }else{
                    url = `${this.$BACKEND_URL}/bookings?tutor=${userDetails.uuid}&status=ONGOING&populate=true&page=${currentPage}&size=${perPage}`;
                }

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.ongoingBookings = response.data.data;
						this.pagination.totalPages = response.data.pagination != null ? response.data.pagination.totalPages : 1;
                        this.pagination.total = response.data.pagination != null ? response.data.pagination.totalItems : 0;
                        this.pagination.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
						this.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            async getCompletedBookings() {

                const userInfo = await localStorage.getItem('user_details')

                this.loadingData = true;

				let userDetails = userInfo != null ? JSON.parse(userInfo) : {};

				this.loadingData = true;

                const { currentPage, perPage, search } = this.pagination;

                let url = '';

                if(search != null && search.length > 0) {                       
                    url = `${this.$BACKEND_URL}/bookings?tutor=${userDetails.uuid}&status=COMPLETED&populate=true&search=${search}&page=${currentPage}&size=${perPage}`;
                }else{
                    url = `${this.$BACKEND_URL}/bookings?tutor=${userDetails.uuid}&status=COMPLETED&populate=true&page=${currentPage}&size=${perPage}`;
                }

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.completedBookings = response.data.data;
						this.pagination.totalPages = response.data.pagination != null ? response.data.pagination.totalPages : 1;
                        this.pagination.total = response.data.pagination != null ? response.data.pagination.totalItems : 0;
                        this.pagination.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
						this.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            async approveBooking(uuid, index) {

                this.bookings[index].loading = true;

                let url = `${this.$BACKEND_URL}/bookings/${uuid}`;

                this.$AXIOS.patch(url, { status: 'ONGOING'}).then(async(response) => {

                    this.notify('Booking has been accepted successfully', 'success')

                    this.bookings[index].loading = false;

                    this.getIncomingBookings();

                }).catch(async(error) => {
                    
                    this.bookings[index].loading = false;

                    this.notify(error.response != null && error.response.data != null && error.response.data.message ? error.response.data.message : 'Connection error', 'error')

                    if(error.response && error.response.status == 401) {
                        await localStorage.setItem("user_token", null);
                        await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
			},


            async declineBooking(uuid, index) {

                this.bookings[index].loading = true;

                let url = `${this.$BACKEND_URL}/bookings/${uuid}`;

                this.$AXIOS.patch(url, { status: 'DECLINED'}).then(async(response) => {

                    this.notify('Booking has been declined successfully', 'success');

                    this.bookings[index].loading = false;

                    this.getIncomingBookings();

                }).catch(async(error) => {
                    
                    this.bookings[index].loading = false;

                    this.notify(error.response != null && error.response.data != null && error.response.data.message ? error.response.data.message : 'Connection error', 'error')

                    if(error.response && error.response.status == 401) {
                        await localStorage.setItem("user_token", null);
                        await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
			},


            notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	/* border-radius: 10px; */
}

a:hover {
    color: inherit !important;
}
</style>